






























































import { Component, Vue } from "vue-property-decorator";
import { FirebaseAuth, UserCredential } from "@firebase/auth-types";
import "firebase/firestore";
import firebase from "firebase/app";
import {
  FirebaseFirestore,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from "@firebase/firestore-types";

@Component
export default class Login extends Vue {
  private message = "";
  private userEmail = "";
  private userPassword = "";
  private uid = "None";
  readonly $appAuth!: FirebaseAuth;
  readonly $appDB!: FirebaseFirestore;

  private googleCredential: string | null = "";

  $router: any;

  private email_rules = [
    (value: any) => !!value || "Required.",
    (value: string | any[]) =>
      (value && value.length >= 3) || "Min 3 characters",
  ];

  private password_rules = [
    (value: any) => !!value || "Required.",
    (value: string | any[]) =>
      (value && value.length >= 6) || "Min 6 characters",
  ];

  get noInput(): boolean {
    return this.userEmail.length === 0 || this.userPassword.length === 0;
  }
  showMessage(m: string): void {
    this.message = m;
    setTimeout(() => {
      // Auto disappear after 5 seconds
      this.message = "";
    }, 5000);
  }

  createAccount(): void {
    this.$appAuth
      .createUserWithEmailAndPassword(this.userEmail, this.userPassword)
      .then((u: UserCredential) => {
        this.showMessage(`User created UID ${u.user?.uid}`);
        this.createProfile();
        this.$router.push({ path: "/games" });
      })
      .catch((err: any) => {
        this.showMessage(`Unable to create account ${err}`);
        console.log(`Unable to create account ${err}`)
      });
  }

  authenticate(): void {
    this.$appAuth
      .signInWithEmailAndPassword(this.userEmail, this.userPassword)
      .then((u: UserCredential) => {
        this.showMessage(`Login successful UID ${u.user?.uid}`);
        this.$router.push({ path: "/games" });
      })
      .catch((err: any) => {
        this.showMessage(`Unable to login ${err}`);
      });
  }

  googleLogin(): void {
    console.log("Google button");
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        // this.googleCredential = "" + credential;
        // The signed-in user info.
        var user = result.user;
        this.showMessage(`Login successful UID ${credential}`);
        // console.log(credential);
        this.createProfile();
        this.$router.push({ path: "/games" });
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;

        console.log(errorCode);
        console.log(errorMessage);
        console.log(email);
        console.log(credential);
      });
  }

  facebookLogin(): void {
    console.log("Facebook button");
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
        // The signed-in user info.
        var user = result.user;
        this.showMessage(`Login successful UID ${credential}`);
        // this.createProfile();
        this.$router.push({ path: "/games" });
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
      });
  }

  createProfile(): void {
    this.uid = this.$appAuth.currentUser?.uid ?? "none";
    this.$appDB
      .collection(`users/profiles/data`)
      .doc(this.uid)
      .set({
        uid: this.uid,
      }, {merge: true});
  }
}
