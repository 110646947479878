







// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import Login from "../components/Login.vue";
import NavComponent from "../components/Nav.vue"

@Component({
  components: {
    Login,
    NavComponent
  },
})
export default class LoginView extends Vue {}
